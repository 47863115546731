import React from "react"
import styled from "styled-components"

// import app components
import * as theme from "theme"
import Hero from "components/hero"
import Edges from "components/edges"
import Spacer from "components/spacer"
import Sidebar from "components/menu/Sidebar"
import ProductNavBar from "components/menu/ProductNavbar"

const SidebarContent = (props) => {
  const {
    image,
    youtubeVideoId,
    title,
    slug,
    hideHeader,
    hideSidebar,
    edgesSize,
    sidebar,
    hideSidebarOnMobile,
    reverseOrderForMobile,
    position = "left",
    children
  } = props

  const productPrefix = ["/outdoor", "/siding", "/decking", "/indoor", "/timbers"]

  //check from productPrefix exists in the slug
  const isProductPage = productPrefix.some((prefix) => slug.startsWith(prefix))
  const isShopPage = slug.includes("architects-builders/order-architect-sample-box")

  return (
    <>
      {!hideHeader && (
        <>
          <Hero image={image} title={title} breadcrumbs={slug} youtubeVideoId={youtubeVideoId} />
          {!isProductPage && <Spacer pb={{ xs: 20, md: 30 }} />}
        </>
      )}

      {!isProductPage ? (
        <Edges size={edgesSize === "none" ? "none" : "md"}>
          <Wrapper>
            {!hideSidebar && (
              <SidebarContainer isShopPage={isShopPage} hideSidebarOnMobile={hideSidebarOnMobile} position={position}>
                {sidebar || <Sidebar slug={slug} />}
              </SidebarContainer>
            )}

            <Content isShopPage={isShopPage} hasSidebar={!hideSidebar} reverseOrderForMobile={reverseOrderForMobile}>
              {children}
            </Content>
          </Wrapper>
        </Edges>
      ) : (
        <div>
          <div className="border-b-2 border-t-2 sticky top-[102px] bg-black border-black z-[99] mb-5 ">
            <Edges size={edgesSize === "none" ? "none" : "md"}>
              <ProductNavBar slug={slug} />
            </Edges>
          </div>
          <Edges size={edgesSize === "none" ? "none" : "lg"}>
            <Wrapper>
              <Content isShopPage={isShopPage} hasSidebar={!hideSidebar} reverseOrderForMobile={reverseOrderForMobile}>
                {children}
              </Content>
            </Wrapper>
          </Edges>
        </div>
      )}
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 899px) {
    flex-wrap: wrap;
  }
`

const SidebarContainer = styled.div`
  width: 100%;
  background: ${theme.colors.lightgrey};
  flex-shrink: 0;
  margin-bottom: 30px;
  order: ${({ position }) => (position === "right" ? 4 : 2)};
  ${(props) => props.hideSidebarOnMobile && `display: none;`}

  @media screen and (min-width: 900px) {
    display: block;
    position: sticky;
    top: 64px;
    width: 250px;
    margin: ${({ position, isShopPage }) =>
      isShopPage ? "0 0 30px 0" : position === "right" ? "0 0 30px 30px" : "0 30px 30px 0"};
  }

  @media screen and (min-width: 1120px) {
    top: 102px;
  }
`

const Content = styled.div`
  max-width: 100%;
  width: 100%;
  ${({ isShopPage }) => !isShopPage && "margin-left: auto;"}
  margin-right: auto;
  order: ${(props) => (props.reverseOrderForMobile ? 1 : 3)};

  @media screen and (min-width: 900px) {
    width: ${(props) => (props.hasSidebar ? "calc(100% - 280px)" : "100%")};
    order: 3;
    ${({ isShopPage }) => isShopPage && "margin-left: 0;"}
  }
`

export default SidebarContent
